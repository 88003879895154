.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-color: var(--clr-bg) !important;

}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  color: var(--clr-primary);
  vertical-align: middle !important;
  text-align: center !important;
  background: transparent !important;
  box-shadow: var(--shadow) !important;
  border-radius: 5px !important;
  display: table !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}
.quote-card-view {
  background: transparent !important;
  border: none !important;
  color: var(--clr-txt) !important;
  box-shadow: var(--shadow);
}
.gitContainer {
  padding: 15px;
  background: transparent !important;
  box-shadow: var(--shadow);
  font-size: 30px !important;
  color: var(--clr-txt);
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.08) !important;
  overflow: hidden !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}
.primary-header {
  color: var(--clr-primary) !important;
}
.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
.git {
  color: var(--clr-primary);
}
